import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ConversationStore, ConversationStoreState } from './index.types';

const initialState: ConversationStoreState = {
  isOptedOut: false,
  optedOutPhoneNumbers: [],
};

const conversationStore = create(
  devtools<ConversationStore>((set, get) => ({
    ...initialState,
    setOptedOutPhoneNumbers: (optedOutPhoneNumbers) =>
      set({ optedOutPhoneNumbers }),
    updateOptedOutPhoneNumbers: (numberToUpdate, optsOut) => {
      const optedOutPhoneNumbers = get().optedOutPhoneNumbers;
      const optedOutPhoneNumberIndex =
        optedOutPhoneNumbers.indexOf(numberToUpdate);

      if (optsOut) {
        if (optedOutPhoneNumberIndex === -1) {
          get().setOptedOutPhoneNumbers([
            ...optedOutPhoneNumbers,
            numberToUpdate,
          ]);
        }
      } else {
        if (optedOutPhoneNumberIndex >= 0) {
          const optedOutPhoneNumbersAux = [...optedOutPhoneNumbers];
          optedOutPhoneNumbersAux.splice(optedOutPhoneNumberIndex, 1);

          get().setOptedOutPhoneNumbers(optedOutPhoneNumbersAux);
        }
      }
    },
    reset: () => set(initialState),
    setIsOptedOut: (isOptedOut) => set({ isOptedOut }),
  }))
);

export const useConversationStore = conversationStore;
