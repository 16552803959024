import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { API_ENDPOINTS } from 'Constants/env';
import {
  IDesktopLocalPreferences,
  IPatchDataItem,
  IPreferenceButtonStates,
  IPreferences,
  IPreferencesWithUpdated,
} from 'Interfaces/preferences';
import { pushToGTMDataLayer } from 'Utils/analytics';
import API from '~/api';

export const QUERY_KEY = 'preferences';

export const defaultPreferences: IPreferencesWithUpdated = {
  listUnreadFirst: true,
  notificationAudio: true,
  floatingSoftphone: false,
  ringVolumePercent: 1.0,
  updated: new Date().toISOString(),
  showCallMessagesInChat: true,
};

export const defaultButtonStates: IPreferenceButtonStates = {
  listUnreadFirst: true,
  notificationAudio: true,
  floatingSoftphone: false,
};

export const defaultDesktopLocalPreferences: IDesktopLocalPreferences = {
  openOnLogin: false,
};

export const fetchPreferences = async (): Promise<IPreferencesWithUpdated & Partial<IDesktopLocalPreferences>> => {
  const { data } = await API.get(API_ENDPOINTS.Preference);
  return data;
};

export const mutationOptions = (queryClient: QueryClient) => ({
  mutationFn: async (preferenceUpdates: IPreferences | null) => {
    const preferencePatchData: IPatchDataItem[] = Object.keys(
      preferenceUpdates
    ).map((key) => ({
      op: 'replace',
      path: `/${key}`,
      value: preferenceUpdates[key],
    }));

    await API.patch(API_ENDPOINTS.Preference, preferencePatchData);
    Object.keys(preferenceUpdates).forEach((key) => {
      pushToGTMDataLayer('preferenceSet', { preferenceName: key });
    });

    return preferenceUpdates;
  },
  onMutate: async (newPreferences: IPreferences | void) => {
    await queryClient.cancelQueries({ queryKey: [QUERY_KEY] });

    const previousPreferences =
      queryClient.getQueryData<IPreferencesWithUpdated>([QUERY_KEY]);

    queryClient.setQueryData<IPreferencesWithUpdated>([QUERY_KEY], (old) => ({
      ...old,
      ...newPreferences,
    }));

    return { previousPreferences };
  },
  onError: (
    err,
    newPreferences,
    context: { previousPreferences?: IPreferencesWithUpdated }
  ) => {
    if (context?.previousPreferences) {
      queryClient.setQueryData([QUERY_KEY], context.previousPreferences);
    }
  },
  onSettled: () => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
  },
});

export const usePreferences = () => {
  const queryClient = useQueryClient();

  const preferencesQuery = useQuery<
    IPreferencesWithUpdated &
      IDesktopLocalPreferences &
      IPreferenceButtonStates,
    Error
  >({
    queryKey: [QUERY_KEY],
    queryFn: async () => {
       const newPreferences = await fetchPreferences();
       // we set the query data with the settings from the cloud, and we append the local openOnLogin setting
       return queryClient.setQueryData<IPreferencesWithUpdated & Partial<IDesktopLocalPreferences>>([QUERY_KEY], (old) => ({
        ...newPreferences,
        openOnLogin : (newPreferences?.openOnLogin ?? old?.openOnLogin ?? defaultDesktopLocalPreferences.openOnLogin)
      }));
    },
  });

  const updatePreferences = useMutation<IPreferences, Error, unknown>(
    mutationOptions(queryClient)
  );

  // Get desktop local preferences from preferencesQuery data
  const desktopLocalPreferences: IDesktopLocalPreferences =
    preferencesQuery?.data && {
      openOnLogin: preferencesQuery.data.openOnLogin,
    };

  // Get button states from preferencesQuery data
  const buttonStates: IPreferenceButtonStates = preferencesQuery?.data && {
    floatingSoftphone: preferencesQuery.data.floatingSoftphone,
    listUnreadFirst: preferencesQuery.data.listUnreadFirst,
    notificationAudio: preferencesQuery.data.notificationAudio,
    showCallMessagesInChat: preferencesQuery.data.showCallMessagesInChat,
  };

  const updateOpenOnLogin = async (openOnLogin: boolean) => {
    // since we using the react query as the only state management for the settings, we only need to append the local openOnLogin setting
    queryClient.setQueryData<IPreferencesWithUpdated>([QUERY_KEY], (old) => ({
      ...old,
      openOnLogin
    }));
    await queryClient.invalidateQueries({queryKey: [QUERY_KEY]}); // invalidate to force refetch and update the cached data
  };

  return {
    preferences: preferencesQuery.data,
    preferencesQuery,
    desktopLocalPreferences,
    buttonStates,
    updatePreferences,
    updateOpenOnLogin
  };
};
