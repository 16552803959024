import { QueryClient } from '@tanstack/react-query';
import {
  QUERY_KEY,
  defaultPreferences,
} from 'Hooks/preferences/usePreferences';
import {
  IDesktopLocalPreferences,
  IPreferencesWithUpdated,
} from 'Interfaces/preferences';


export class PreferenceStore {
  #preferences: IPreferencesWithUpdated;
  #queryClient: QueryClient;

  constructor(queryClient: QueryClient) {
    this.#queryClient = queryClient;
  }

  get preferences(): IPreferencesWithUpdated {
    return this.#preferences || defaultPreferences;
  }

  getExistingPreferenceData = async () => {
    const queryState = this.#queryClient.getQueryState<IPreferencesWithUpdated>(
      [QUERY_KEY]
    );
    return (this.#preferences = queryState?.data || defaultPreferences);
  };

  clearAllData = () => {
    void this.#queryClient.invalidateQueries({
      queryKey: [QUERY_KEY],
    });
  };

  setDesktopLocalPreferencesData = async ({
    openOnLogin,
  }: IDesktopLocalPreferences) => {
    this.#queryClient.setQueryData<IDesktopLocalPreferences>(
      [QUERY_KEY],
      (oldData) => ({ ...oldData, openOnLogin}), // we keep existing preferences, aka, data saved on the cloud, and append the local setting
    );
    await this.#queryClient.invalidateQueries({queryKey: [QUERY_KEY]}) // in the electron app, this function is called before the query fetch, so we invalidate in order to trigger the fetch
  };
}

export default PreferenceStore;
