
import EmojiActive from 'Assets/images/emoji-active.svg';
import EmojiOnHover from 'Assets/images/emoji-hover.svg';
import EmojiInactive from 'Assets/images/emoji-inactive.svg';
import { Picker as EmojiPicker } from 'emoji-mart';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { Styled } from './index.styles';
import { EmojiBoxDirection, EmojiButtonProps } from './types';

export const EmojiButton: React.FC<EmojiButtonProps> = ({
  isOpen,
  onClose,
  onOpen,
  onChooseEmoji,
  relativePosition,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [emojiBoxDirection, setEmojiBoxDirection] =
    React.useState<EmojiBoxDirection>('top');

  useEffect(() => {
    const handlerOutsideClick = (e) => {
      const isClickOutsideOfWrapper =
        wrapperRef?.current &&
        !wrapperRef?.current.contains(e.target as HTMLElement);

      if (isClickOutsideOfWrapper) {
        onClose();
      }
    };

    window.addEventListener('mousedown', handlerOutsideClick);

    return () => window.removeEventListener('mousedown', handlerOutsideClick);
  }, [onClose]);

  useLayoutEffect(() => {
    if (!isOpen || !wrapperRef?.current) {
      return;
    }
    const wrapperDiv = wrapperRef.current?.getBoundingClientRect();
    if (!wrapperDiv) {
      return;
    }
    const availableTopSpace = wrapperDiv.top;
    if (availableTopSpace < 70 + 360 + 110) {
      // 70 = header bar height, 360 = rough height of the emoji picker, 110 = some offset
      setEmojiBoxDirection('bottom');
    } else {
      setEmojiBoxDirection('top');
    }
  }, [isOpen]);

  return (
    <Styled.EmojiWrapper
      className="emoji-wrapper"
      onKeyDown={(e) => {
        if (e.key !== 'Escape') return;
        onClose();
        e.preventDefault();
        e.stopPropagation();
      }}
      $relative={relativePosition}
      ref={wrapperRef}
      data-testid={`emojiWrapper`}
    >
      {isOpen && (
        <Styled.EmojiBox data-testid={`emojiBox`} direction={emojiBoxDirection}>
          <EmojiPicker onSelect={onChooseEmoji} autoFocus onClose={onClose} />
        </Styled.EmojiBox>
      )}
      <Styled.EmojiIconWrapper
        onClick={(e) => {
          isOpen ? onClose() : onOpen();

          e.preventDefault();
          e.stopPropagation();
        }}
        data-testid={`emojiIconWrapper`}
      >
        <img
          src={isOpen ? EmojiActive : EmojiInactive}
          onMouseOver={(e) =>
            (e.currentTarget.src = isOpen ? EmojiActive : EmojiOnHover)
          }
          onMouseLeave={(e) =>
            (e.currentTarget.src = isOpen ? EmojiActive : EmojiInactive)
          }
          data-testid={`emojiIcon`}
        />
      </Styled.EmojiIconWrapper>
    </Styled.EmojiWrapper>
  );
};
