import type { SourceBookProps } from './types';
import { ButtonManageSocialAccount } from 'Components/shared/ButtonManageSocialAccount';
import { ConfirmationModal } from 'Components/shared/ConfirmationModal';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { DropdownItemProps, Loader } from 'semantic-ui-react';
import {
  STORE_CONTACT,
  STORE_CONVERSATION,
  STORE_NOTIFICATION,
  STORE_PARTICIPANT,
  STORE_PERSON,
  STORE_PHONE_CALL,
  STORE_UI,
} from '../../constants/stores';
import withRouter from '../../hocs/WithRouter';
import { ISourceAccount } from '../../interfaces/SourceAccount';
import GoogleAuthLogin from '../GoogleAuthLogin';
import ICloudAddressBook from '../ICloudAddressBook';
import MicrosoftBusiness from '../MicrosoftBusiness';
import MicrosoftPersonal from '../MicrosoftPersonal';
import SuccessScreenSource from '../SuccessScreenSource';
import { Styled } from './index.styles';

const bhiveIcon = require('../../assets/images/bhive-logo.svg');

export const permanentProviders: ISourceAccount[] = [
  {
    id: 99,
    name: 'Personal contacts',
    provider: 'Bhive',
    providerImage: bhiveIcon,
    text: 'Your personal contacts stored on b-hive and visible only to you. Source can not be removed.',
    email: '',
    providerAccountId: '',
    platformUserId: null,
    platformAccountId: null,
    createdAt: new Date().toString(),
    scopes: ['contacts'],
  },
  {
    id: 100,
    name: 'Organization contacts',
    provider: 'Bhive',
    providerImage: bhiveIcon,
    text: 'Your account contacts stored on b-hive and visible to everyone on your account. Source can not be removed.',
    email: '',
    providerAccountId: '',
    platformUserId: null,
    platformAccountId: null,
    createdAt: new Date().toString(),
    scopes: ['contacts'],
  },
];

export const SourcesAddressBook = inject(
  STORE_PERSON,
  STORE_CONVERSATION,
  STORE_UI,
  STORE_PARTICIPANT,
  STORE_CONTACT,
  STORE_PHONE_CALL,
  STORE_NOTIFICATION
)(
  withRouter(
    observer(
      ({
        person,
        navigate,
        notification,
        titleFor,
        testId: parentTestId,
      }: SourceBookProps) => {
        const testId = `${parentTestId}-sourcesAddressBook`;
        const [showRemoveNotification, setShowRemoveNotification] =
          useState(false);
        const [showRemoveModal, setShowRemoveModal] = useState(false);
        const [sourceForRemoval, setSourceForRemoval] = useState(null);
        const removeSource = async () => {
          const resp = await person.removeSource(sourceForRemoval);

          if (resp) {
            setShowRemoveNotification(true);
            setShowRemoveModal(false);

            const timeout = setTimeout(() => {
              setShowRemoveNotification(false);
              clearTimeout(timeout);
            }, 1300);
          }
        };

        const makeProperNamingRemoval = (): [string, string] => {
          return titleFor === 'contacts'
            ? ['sources', 'contacts']
            : ['calendars', 'calendar events'];
        };

        const toggleRemoveModal = (source: ISourceAccount) => () => {
          setShowRemoveModal(!showRemoveModal);
          setSourceForRemoval(source);
        };

        const showContactsBasedOnSource = useCallback(
          (source: ISourceAccount) => () => {
            const text =
              source.email.charAt(0).toUpperCase() + source.email.slice(1);
            const providerValue =
              source.name === 'Personal contacts'
                ? 'B_HIVE_PERSONAL'
                : source.name === 'Organization contacts'
                ? 'B_HIVE_SHARED'
                : source.id;
            const pickedSource: DropdownItemProps = {
              key: source.id,
              image: { avatar: true, src: source.providerImage },
              text: text,
              value: providerValue,
            };
            person.setContactFilterValue(pickedSource);
            person.getSearchListContacts(
              20,
              1,
              pickedSource.value.toString() || '',
              person.contactSearchTerm
            );
            navigate('/addressBook/contacts');
          },
          [navigate]
        );

        const resyncSource = (source: ISourceAccount) => () =>
          void person.resyncSource(source);

        const checkActionTimeout = (source: ISourceAccount) => {
          const dateNow = moment.utc();

          const dateSynced = moment(source.lastSyncedAt || new Date());
          const dateCrated = moment(source.createdAt);
          return {
            createdBefore5min: dateNow.diff(dateCrated, 'minutes') < 5,
            syncBefore5min: dateNow.diff(dateSynced, 'minutes') < 5,
          };
        };

        const handleProperName = () => {
          return person.changeProviderName(
            person.addedNewSource.sourceProvider || ''
          );
        };

        const filterByScopes = (data: ISourceAccount[]) => {
          const filterScope = titleFor === 'contacts' ? 'contacts' : 'calendar';
          return data?.filter((item) =>
            item.scopes.some((scope) => scope.includes(filterScope))
          );
        };

        const data = filterByScopes([
          ...permanentProviders,
          ...person.allSources,
        ]);
        const providerName = handleProperName() || '';
        const [removeFromWhere, removeWhat] = makeProperNamingRemoval();

        return (
          <div className="source-list">
            {person.addedNewSource.show ? (
              <SuccessScreenSource
                typeOfAddedAction={titleFor}
                alreadyAddedMessage={person.addedNewSource.alreadyAddedMessage}
                provider={providerName}
                person={person}
                showContactsBasedOnSource={showContactsBasedOnSource}
              />
            ) : (
              <>
                <div className="existing-source">Existing {titleFor}</div>
                <div className="source-wrapper">
                  {data?.length > 0 ? (
                    data.map((source) => {
                      const { createdBefore5min, syncBefore5min } =
                        checkActionTimeout(source);
                      return (
                        <div
                          className={`source-item ${
                            syncBefore5min ? 'source-item--syncing' : ''
                          } ${createdBefore5min ? 'source-item--created' : ''}`}
                          key={source.id * (source.key || 11)}
                        >
                          <div className="contact flex-row">
                            <span>{source.name}</span>
                            <span className="source-icon">
                              <img src={source.providerImage} />
                            </span>
                            {titleFor === 'contacts' && (
                              <span
                                className="show"
                                onClick={showContactsBasedOnSource(source)}
                                data-testid={`${testId}-buttonShow`}
                              >
                                SHOW
                              </span>
                            )}
                          </div>
                          <div className="content">
                            {source.text || source.email}
                          </div>
                          {!source.provider.includes('Bhive') && (
                            <div className="flex-row source-item--actions">
                              <ButtonManageSocialAccount
                                action="RESYNC"
                                disabled={syncBefore5min}
                                icons={{
                                  enabled: 'sync-black',
                                  disabled: 'sync-gray',
                                }}
                                onClick={
                                  !syncBefore5min ? resyncSource(source) : null
                                }
                                testid={`${testId}-buttonResync`}
                              />
                              <ButtonManageSocialAccount
                                action="REMOVE"
                                disabled={createdBefore5min}
                                icons={{
                                  enabled: 'source-black-link',
                                  disabled: 'source-gray-link',
                                }}
                                onClick={
                                  !createdBefore5min
                                    ? toggleRemoveModal(source)
                                    : null
                                }
                                testid={`${testId}-buttonRemove`}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })
                  ) : data.length === 0 ? (
                    <div>
                      Your list is empty. Click on the buttons below to add new
                      Source to the list.
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
                <div className="source-boxes flex-row">
                  <GoogleAuthLogin
                    {...{ person }}
                    for={titleFor}
                    testId={testId}
                  />
                  <MicrosoftBusiness
                    {...{ person }}
                    for={titleFor}
                    testId={testId}
                  />
                  <MicrosoftPersonal
                    {...{ person }}
                    notification={notification}
                    for={titleFor}
                    testId={testId}
                  />
                  <ICloudAddressBook
                    {...{ person }}
                    notification={notification}
                    for={titleFor}
                    testId={testId}
                  />
                </div>

                <Styled.Disclaimer variant="paragraph">
                  Your contacts will be synchronized between b-hive and your
                  external account. Contacts will be visible only to you. Any
                  changes in the b-hive will be propagated to your external
                  source and vice versa.
                </Styled.Disclaimer>

                {showRemoveNotification && (
                  <div className="remove-notification">
                    Source {sourceForRemoval?.name} succesfully removed.
                  </div>
                )}

                <ConfirmationModal
                  show={showRemoveModal}
                  onClose={toggleRemoveModal(null)}
                  onConfirm={removeSource}
                  textConfirm="Yes"
                  textCancel="No"
                  testid={`${testId}-remove`}
                >
                  <div>
                    You are about to remove <b>{sourceForRemoval?.name}</b> from
                    your {removeFromWhere}. All related {removeWhat} will be
                    removed. Are you sure?
                  </div>
                </ConfirmationModal>
              </>
            )}
          </div>
        );
      }
    )
  )
);
