import type { MessageBubbleProps } from './types';
import { FilesPreview } from 'Components/Chat/ChatLayout/FilesPreview';
import { LinkPreview } from 'Components/Chat/ChatLayout/LinkPreview';
import { ChatLayoutContext } from 'Components/Chat/contexts/ChatLayoutContext';
import { MobXProviderContext } from 'mobx-react';
import React from 'react';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { useMarkdownCompiler } from 'Utils/markdownCompiler';
import { Styled } from './index.styles';
import { MessageBubbleEditor } from './MessageBubbleEditor';
import { PinTextButton } from './PinTextButton';
import { ThreeDotsButton } from './ThreeDotsButton';
import { usePinnedStatus } from './usePinnedStatus';

const testid = 'messageBubble';

export const MessageBubble = ({
  AvatarComponent,
  content,
  conversationId,
  displayName,
  isOwnMessage = false,
  message,
  messageDate,
  onClick,
}: MessageBubbleProps) => {
  const { conversation } =
    React.useContext<RootStoreProps>(MobXProviderContext);
  const [showThreeDots, setShowThreeDots] = React.useState(false);
  const [openThreeDots, setOpenThreeDots] = React.useState(false);
  const { editMessageId, view } = React.useContext(ChatLayoutContext);
  const { compileViewerMarkdown, compileEditorMarkdown } = useMarkdownCompiler(
    message.id,
    message.references
  );

  const compiledContent = React.useMemo(() => {
    let compiledContent = compileViewerMarkdown(
      message.chat?.text_v2 || content
    );

    // compileViewerMarkdown adds \n\n to the end of strings, so we have to remove it, otherwise there will be a line break at the end of the message
    if (Array.isArray(compiledContent)) {
      compiledContent = compiledContent.map((element) => {
        if (typeof element === 'string' && element.endsWith('\n\n')) {
          return element.slice(0, -2);
        }

        return element;
      });
    }

    return compiledContent;
  }, [compileViewerMarkdown, content]);

  const { pinnedBy, togglePinMessage } = usePinnedStatus(
    message.id,
    conversationId
  );

  if (!content && !message.documents?.length && !message.isDeleted) return null;

  return editMessageId === message.id && view !== 'pinned-messages' ? (
    <MessageBubbleEditor
      {...{ message, conversationId, compileEditorMarkdown }}
    />
  ) : (
    <Styled.ParentBox id={message.id} $isOwnMessage={isOwnMessage}>
      {conversation?.CurrentConversation?.isActiveParticipant && pinnedBy && (
        <PinTextButton
          {...{ isOwnMessage, pinnedBy, togglePinMessage, testid }}
        />
      )}

      <Styled.BoxWithAvatar>
        {AvatarComponent && !isOwnMessage && (
          <Styled.AvatarContainer>{AvatarComponent}</Styled.AvatarContainer>
        )}
        <Styled.Box
          $isOwnMessage={isOwnMessage}
          onMouseEnter={() => setShowThreeDots(true)}
          onMouseLeave={() => !openThreeDots && setShowThreeDots(false)}
          {...{ onClick }}
        >
          <Styled.NameDateContainer>
            <Styled.Name
              $showThreeDots={
                conversation?.CurrentConversation?.isActiveParticipant &&
                !message.isDeleted &&
                showThreeDots
              }
              variant="paragraph"
              noMargin
            >
              {displayName}
            </Styled.Name>
            <Styled.Date variant="subtitles" noMargin>
              {messageDate}
            </Styled.Date>
            {message?.updated && !message.sms?.smsId && (
              <Styled.EditTag variant="subtitles" noMargin>
                Edited
              </Styled.EditTag>
            )}
            {conversation?.CurrentConversation?.isActiveParticipant &&
              showThreeDots &&
              !message.isDeleted && (
                <ThreeDotsButton
                  {...{
                    conversationId,
                    isOwnMessage,
                    message,
                    openThreeDots,
                    setOpenThreeDots,
                    setShowThreeDots,
                    testid,
                    togglePinMessage,
                    pinnedBy,
                  }}
                />
              )}
          </Styled.NameDateContainer>
          <Styled.Message variant="paragraph">
            {message.isDeleted ? (
              <i>This message was deleted</i>
            ) : (
              compiledContent
            )}
          </Styled.Message>
          {!!message.documents?.length && (
            <FilesPreview {...{ message, isOwnMessage }} />
          )}
          {!message?.isDeleted && message?.linkPreview?.url && (
            <LinkPreview {...message.linkPreview} />
          )}
        </Styled.Box>
      </Styled.BoxWithAvatar>
    </Styled.ParentBox>
  );
};
