import cx from 'classnames';
import { observer } from 'mobx-react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Dropdown, DropdownProps } from 'semantic-ui-react';
import { getElementAbsolutePosition } from 'Utils/domPositioning';
import { IParticipantPerson } from '../../interfaces/participantPerson';
import { focusContentEditable } from '../../utils/inputNodeUtils';
import AtMentionHereItemObserver from '../AtMentionHereItem';
import AtMentionMemberItemObserver from '../AtMentionMemberItem';
import { AtMentionMembersProps } from './interfaces';

/**
 * List of `Participants` in a `Channel` who can be mentioned.
 *
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 */
export class AtMentionMembers extends React.Component<
  AtMentionMembersProps,
  { openDownward: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      openDownward: false,
    };
  }

  onMentionItemClick = (personIdStr: string) => {
    const { insertMentionAtCursor, contentEditableRef } = this.props;
    focusContentEditable(contentEditableRef.current, undefined, true);
    insertMentionAtCursor('@pr', `${personIdStr}`);
  };

  onHereClick = (e: React.MouseEvent<Element>) => {
    const { insertMentionAtCursor } = this.props;
    e.preventDefault();
    insertMentionAtCursor('@', 'here');
  };

  toggleVisibility = () => {
    const {
      mentionListOpen,
      setMentionListOpen,
      setSelectedMentionParticipantId,
      setEmojiPickerState,
    } = this.props;
    setEmojiPickerState({ open: false, editing: false });
    setMentionListOpen(!mentionListOpen);
    if (!mentionListOpen) {
      if (this.dropdownRef) {
        const dropdownElem = ReactDOM.findDOMNode(
          this.dropdownRef
        ) as HTMLDivElement;
        const absPos = getElementAbsolutePosition(dropdownElem);
        this.setState({
          openDownward: absPos.top < absPos.maxAncestorClientHeight / 2,
        });
      }
      setSelectedMentionParticipantId('here');
    } else if (mentionListOpen) {
      setSelectedMentionParticipantId(null);
    }
  };

  isSelected = (p: IParticipantPerson) => {
    const { selectedMentionParticipantId: smpID } = this.props;
    return (
      (smpID === p.participant.id ||
        smpID === p.participant.personId?.toString()) &&
      !!smpID
    );
  };

  private menuRef: HTMLDivElement;
  private registerMenuRef = (elem: HTMLDivElement) => (this.menuRef = elem);

  private dropdownRef: React.Component<DropdownProps>;
  private registerDropdownRef = (elem: React.Component<DropdownProps>) =>
    (this.dropdownRef = elem);

  render() {
    const {
      conversationId,
      loggedInPersonId,
      mentionListOpen,
      messageMentionFilter,
      onKeyDown,
      resolveConversationLinkPath,
      selectedMentionParticipantId,
      selectFilteredOtherParticipantPersonsInCurrentConversation,
      selectPersonPresenceStatus,
      selectUnreadCounts,
      setConversationAndTotalUnreadCount,
      updateMyLastReadMessage,
      className,
    } = this.props;
    const { openDownward } = this.state;
    const filteredOtherPtcPersons =
      selectFilteredOtherParticipantPersonsInCurrentConversation(
        messageMentionFilter
      );
    return (
      <Dropdown
        className={cx('mention-dropdown', className)}
        icon="at"
        onClick={this.toggleVisibility}
        ref={this.registerDropdownRef}
        open={mentionListOpen}
        onKeyDown={onKeyDown}
      >
        <Dropdown.Menu
          className={cx('mention-dropdown-menu', {
            'mention-dropdown-menu-flipped': this.state.openDownward,
          })}
        >
          {openDownward && <div className="top-triangle" />}
          <div
            className="mention-members-list menu scrolling"
            ref={this.registerMenuRef}
          >
            <AtMentionHereItemObserver
              key="here"
              isSelected={selectedMentionParticipantId === 'here'}
              conversationId={conversationId}
              resolveConversationLinkPath={resolveConversationLinkPath}
              onHereClick={this.onHereClick}
            />
            {filteredOtherPtcPersons &&
              filteredOtherPtcPersons.length > 0 &&
              filteredOtherPtcPersons
                .filter((p) => p.person.id !== loggedInPersonId)
                .map((p) => (
                  <AtMentionMemberItemObserver
                    conversationId={conversationId}
                    isSelected={this.isSelected(p)}
                    key={p.participant.id}
                    onMentionClick={this.onMentionItemClick}
                    participantPerson={p}
                    resolveConversationLinkPath={resolveConversationLinkPath}
                    selectPersonPresenceStatus={selectPersonPresenceStatus}
                    selectUnreadCounts={selectUnreadCounts}
                    setConversationAndTotalUnreadCount={
                      setConversationAndTotalUnreadCount
                    }
                    updateMyLastReadMessage={updateMyLastReadMessage}
                  />
                ))}
          </div>
          {!openDownward && <div className="bottom-triangle" />}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default observer(AtMentionMembers);
