import type { ChatLayoutHeaderActionsRendererProps } from './ChatLayoutHeaderActionsRenderer.types';
import { MobXProviderContext, observer } from 'mobx-react';
import * as React from 'react';
import { Loader, Message } from 'semantic-ui-react';
import type { RootStoreProps } from 'Stores/RootStore.types';

import { ChatLayoutHeaderActions } from '.';

const testid = 'chatLayoutHeaderActionsRenderer';

export const ChatLayoutHeaderActionsRenderer: React.FC<ChatLayoutHeaderActionsRendererProps> =
  observer(({ conversationId }) => {
    const {
      conversation: {
        selectConversationById,
        LoggedInUserActiveConferenceConversation,
      },
      participant: {
        selectOtherParticipants,
        selectParticipantsByConversationId,
      },
      person: {
        selectPersonById,
        getExtrContactByPhoneNumber,
        personAvaliableFeatures,
      },
    } = React.useContext<RootStoreProps>(MobXProviderContext);

    const convPbo = selectConversationById(conversationId);

    if (!convPbo) return <Loader active indeterminate size="mini" />;

    return convPbo.case({
      pending: () => <Loader indeterminate active />,
      rejected: ({ response }) =>
        !response ? null : (
          <Message
            visible
            error
            content={`Error loading Conversation: ${response.data.message}`}
          />
        ),
      fulfilled: (convResp) => {
        const isGroupChat =
          convResp.data.grouping === 'Group' ||
          convResp.data.grouping === 'Channel';

        const isAdHocGroupChat = convResp.data.grouping === 'Group';

        const commonProps = {
          conversationId,
          isGroupChat,
          testid,
          isAdHocGroupChat,
        };

        if (convResp.data.grouping === 'OneOnOne') {
          const otherParticipants = selectOtherParticipants(conversationId);
          if (otherParticipants?.length === 1) {
            const otherPersonId = otherParticipants[0].personId;
            if (otherPersonId) {
              const personPbo = selectPersonById(otherPersonId);
              // Is an internal communicator user?
              return (
                <ChatLayoutHeaderActions
                  {...commonProps}
                  personDetails={{ id: otherPersonId, type: 'person' }}
                  call={{
                    type: 'personId',
                    value:
                      personPbo?.state === 'fulfilled' &&
                      personPbo?.value.data.id,
                  }}
                  video={{
                    disabled:
                      !!LoggedInUserActiveConferenceConversation ||
                      !personAvaliableFeatures.video.enabled,
                  }}
                />
              );
            }

            if (otherParticipants[0].phone) {
              const externalContact = getExtrContactByPhoneNumber(
                otherParticipants[0].phone
              );
              return (
                <ChatLayoutHeaderActions
                  {...commonProps}
                  personDetails={{
                    id: otherParticipants[0].phone,
                    type: externalContact ? 'extrContact' : 'contact',
                  }}
                  call={{ type: 'phone', value: otherParticipants[0].phone }}
                />
              );
            }
          } else {
            return (
              <ChatLayoutHeaderActions {...commonProps} isOwnConversation />
            );
          }
        }

        if (isGroupChat) {
          return (
            <React.Fragment>
              {selectParticipantsByConversationId(conversationId)?.case({
                pending: () => <Loader indeterminate active />,
                rejected: ({ response }) =>
                  !response ? null : (
                    <Message
                      visible
                      error
                      content={`Error loading Conversation: ${response.data.message}`}
                    />
                  ),
                fulfilled: () => (
                  <ChatLayoutHeaderActions
                    {...commonProps}
                    video={{
                      disabled:
                        !!LoggedInUserActiveConferenceConversation ||
                        !personAvaliableFeatures.video.enabled,
                    }}
                  />
                ),
              })}
            </React.Fragment>
          );
        }

        return null;
      },
    });
  });
