import gearIcon from 'Assets/images/settings-plain.svg';
import chatIcon from 'Assets/images/sms-icon.svg';
import ChannelInfoViewObserver from 'Components/ChannelInfoView';
import { STORE_CONFIG } from 'Constants/stores';
import { inject, observer } from 'mobx-react';
import * as React from 'react';

import { Link } from 'react-router-dom';
import { Popup, Segment } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import {
  selectProperVideoIcon,
  selectProperVideoTooltipMessageForGroups,
} from 'Utils/videoConferenceUtils';

import { ChannelViewProps } from './interface';

/**
 * **This named class is not decorated with `@observer`.** Use the default export from this module if you need the decorated class.
 *
 * @export
 * @class ChannelView
 */
export class ChannelView extends React.Component<ChannelViewProps, {}> {
  placeVideoCallConference = () => {
    const { conversationId, postConferenceByConversationId } = this.props;
    postConferenceByConversationId(conversationId);
    if (window['dataLayer']) {
      window['dataLayer'].push({
        event: 'placeVideoConference',
        conversationId: conversationId,
      });
    }
  };

  openGroupModal = () => {
    const {
      conversation,
      setGroupModal,
      setChannelInfoDetails,
      setMoreActionOptions,
      setOpeningGroupModalFrom,
    } = this.props;
    setGroupModal(true);
    setChannelInfoDetails(conversation.id, 'edit');
    setMoreActionOptions({ show: false, conversationId: '' });
    setOpeningGroupModalFrom('edit');
  };

  render() {
    const {
      conversation,
      loggedInUserActiveConferenceConversation,
      config,
      handleCloseButton,
      loggedInPersonVideoFeature,
    } = this.props;
    const conferenceIsActive = !isNullOrUndefined(
      conversation?.activeConference
    );
    const userIsInActiveVideoConference = !isNullOrUndefined(
      loggedInUserActiveConferenceConversation
    );

    const videoIconSrc = selectProperVideoIcon(
      null,
      null,
      false,
      false,
      'group-info'
    );
    const videoTooltipMessage = selectProperVideoTooltipMessageForGroups(
      conferenceIsActive,
      userIsInActiveVideoConference
    );
    const videoIconMarkup = <img className={'video-icon'} src={videoIconSrc} />;
    const isSMSConversation =
      conversation?.participants.some((participant) => participant.phone) ??
      false;

    if (conversation === null || conversation?.topic === undefined) {
      handleCloseButton('group-info');
      return <></>;
    } else {
      return (
        <div className="channel-view flex-shrink">
          <ChannelInfoViewObserver conversation={conversation} />
          {conversation.isActiveParticipant && (
            <Segment
              textAlign="center"
              className="no-vpadding"
              clearing
              basic
              style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}
            >
              <Popup
                inverted
                position="bottom left"
                content="Go to Conversation"
                trigger={
                  <button
                    className="custom-button"
                    id="open-chat-group-sidebar"
                  >
                    <Link to={`/chat/conversations/${conversation?.id}/menu`}>
                      <img className="chat-icon" src={chatIcon} />
                    </Link>
                  </button>
                }
              />
              {loggedInPersonVideoFeature.enabled && !isSMSConversation && (
                  <>
                    <Popup
                      inverted
                      position="bottom left"
                      trigger={
                        <button
                          className={`custom-button ${
                            conferenceIsActive ? 'active' : ''
                          }`}
                          onClick={this.placeVideoCallConference}
                          disabled={
                            userIsInActiveVideoConference ||
                            !loggedInPersonVideoFeature.enabled
                          }
                        >
                          <Link
                            to={`/chat/conversations/${conversation?.id}/menu`}
                          >
                            {videoIconMarkup}
                          </Link>
                        </button>
                      }
                    >
                      <div>
                        <div>{videoTooltipMessage}</div>
                      </div>
                    </Popup>
                  </>
                )}
              {conversation.grouping !== 'Group' && (
                <Popup
                  inverted
                  position="bottom left"
                  content="Edit Group"
                  trigger={
                    <button
                      className="custom-button"
                      onClick={this.openGroupModal}
                      id="edit-group"
                    >
                      <img className="settings-icon" src={gearIcon} />
                    </button>
                  }
                />
              )}
            </Segment>
          )}
        </div>
      );
    }
  }
}

export default inject(STORE_CONFIG)(observer(ChannelView));
