import type { NotificationProps } from './interfaces';
import { STORE_NOTIFICATION } from 'Constants/stores';
import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import NotificationSystem from 'react-notification-system';

export const Notification: React.FC<NotificationProps> = (props) => {
  const [addedNotifiations, setAddedNotifiations] = React.useState<
    NotificationSystem.Notification[]
  >([]);
  const [nsInitialized, setNsInitialized] = React.useState(false);
  const [notificationSystem, setNotificationSystem] =
    React.useState<NotificationSystem>(null);

  const mobxHook = reaction(
    () =>
      nsInitialized ? props.notification.PendingRnsNotifications.length : -1,
    (notifCount) => {
      if (notifCount > 0 && notificationSystem !== null) {
        props.notification.PendingRnsNotifications.forEach((s) => {
          setAddedNotifiations([...addedNotifiations, s]);
          notificationSystem.addNotification(s);
        });
      }
    }
  );

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      const allNotifications = document.querySelectorAll(
        '.notification-visible'
      );
      allNotifications?.forEach((notification) => {
        notification.setAttribute('data-private', 'true');
      });
      clearTimeout(timeout);
    }, 100);

    return () => mobxHook?.();
  }, [mobxHook, props.notification.notifications]);

  const registerNs = (el: NotificationSystem) => {
    setNotificationSystem(el);
    setNsInitialized(true);
  };

  return <NotificationSystem ref={registerNs} />;
};

export default inject(STORE_NOTIFICATION)(observer(Notification));
