import styled, { css } from 'styled-components';

import { EmojiBoxDirection } from './types';

const EmojiWrapper = styled.div<{ $relative: boolean }>`
  ${({ $relative }) =>
    $relative && `
      right: 10px;
      bottom: 0px;
      margin-left: 10px;
      position: relative !important;
    `}
`;

const EmojiIconWrapper = styled.div`
  display: flex;
  width: 15px;
  height: 15px;
  z-index: 2;
  cursor: pointer;
`;
const EmojiBox = styled.div<{direction?: EmojiBoxDirection}>`
  ${({ direction }) => direction === 'bottom'
    ? css`
      top: 39px;
      &:after {
        top: -6px;
        border-bottom: 7px solid ${({ theme }) => theme.colors.border};
    }
    `
    : css`
      bottom: 37px;
      &:after {
        bottom: -6px;
        border-top: 7px solid ${({ theme }) => theme.colors.border};
      }
    `
  };

  position: absolute;
  display: flex;
  right: -2px;
  z-index: 2;

  &:after {
    content: '';
    position: absolute;
    right: 6px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
`
EmojiBox.defaultProps = { direction: 'top' };

export const Styled = {
  EmojiWrapper,
  EmojiIconWrapper,
  EmojiBox,
};
