import holdCallIcon from 'Assets/images/btn-call-hold-black.svg';
import holdCallIconWhite from 'Assets/images/btn-call-hold.svg';
import callKeypadIcon from 'Assets/images/btn-keypad-black.svg';
import callKeypadIconWhite from 'Assets/images/btn-keypad.svg';
import callMuteIcon from 'Assets/images/btn-mute-black.svg';
import callMuteIconWhite from 'Assets/images/btn-mute.svg';
import transferIcon from 'Assets/images/btn-transfer-black.svg';
import transferIconWhite from 'Assets/images/btn-transfer.svg';
import endCallIcon from 'Assets/images/icon-phonecalls.svg';
import moreIcon from 'Assets/images/merge-call.svg';
import disabledVideoIcon from 'Assets/images/no-video-white.svg';
import videoIcon from 'Assets/images/video-plain-black.svg';
import videoIconWhite from 'Assets/images/video-plain.svg';
import cx from 'classnames';
import { IS_ELECTRON } from 'Constants/env';
import { STORE_CONFIG } from 'Constants/stores';
import { get } from 'lodash';
import { inject, observer, MobXProviderContext } from 'mobx-react';
import { PersonModel } from 'Models/PersonModel';
import * as React from 'react';
import { Button, Grid, Image, Popup } from 'semantic-ui-react';
import { isNullOrUndefined } from 'util';
import { pushToGTMDataLayer } from 'Utils/analytics';
import { configureConferencePopupWindow } from 'Utils/windowUtils';
import { RootStoreProps } from '../../stores/RootStore.types';
import { CallActiveButtonsProps } from './interfaces';

interface CallActiveButtonsState {
  person?: PersonModel;
}

/**
 * Active Call component
 *
 * @export
 * @class ActiveCall
 */
export class CallActiveButtons extends React.Component<
  CallActiveButtonsProps,
  CallActiveButtonsState
> {
  static contextType = MobXProviderContext;
  declare context: React.ContextType<typeof MobXProviderContext>;

  state: CallActiveButtonsState = {
    person: undefined,
  };

  componentDidMount() {
    this.getPersonFromPhoneCall();
  }

  warmTransfer = () => {
    const { phoneCallModel } = this.props;
    phoneCallModel.forwardWarmTransfer();
  };

  endWarmTransferCall = () => {
    const { endCall, phoneCallModel, oldSession } = this.props;
    endCall();
    phoneCallModel.setTransferMode(true);
    phoneCallModel.setWarmTransfer(false);
    oldSession.setTransferMode(true);
  };

  initiateVideoCall = () => {
    const { loadOrCreateConversationWithPost, postConferenceByConversationId } =
      this.props;
    const { contact, person } = this.props.phoneCallModel;

    // Create a `Window` reference immediately on click, to allow the popup to open
    // Only need `windowRef` to be non-null in Browser (NOT Electron) env
    const windowRef = !IS_ELECTRON ? configureConferencePopupWindow() : null;

    // Need to do this call and create convId with active caller
    // will not always be the case that convId already exists
    // Create video conference once convId has been retrieved
    if (!isNullOrUndefined(person)) {
      return person
        .then((p) => p.data.id)
        .then((pId) =>
          loadOrCreateConversationWithPost(pId)
            .then((resp) => resp.data.id)
            .then((cId: string) =>
              postConferenceByConversationId(cId, windowRef)
            )
        );
    } else if (!isNullOrUndefined(contact)) {
      return contact
        .then((c) => c.data.phoneNumber)
        .then((pNum) =>
          loadOrCreateConversationWithPost(null, pNum)
            .then((resp) => resp.data.id)
            .then((cId: string) =>
              postConferenceByConversationId(cId, windowRef)
            )
        );
    } else {
      console.error(
        'initiateVideoCall failed: both person and contact were null/undefined'
      );
    }
  };

  handleMerge = (e) => {
    e.persist();
    const { transferToConference } = this.props;
    const ConferenceIdGuid = window.location.pathname
      .split('/')
      .splice(-2, 1)
      .join();
    pushToGTMDataLayer('callMerge', {
      conferenceRoom: ConferenceIdGuid,
    });
    transferToConference();
  };

  getPersonFromPhoneCall = () => {
    const { person } = this.props.phoneCallModel;
    if (!isNullOrUndefined(person)) {
      person.then((p) => {
        this.setState({
          person: p.data,
        });
      });
    }
  };

  render() {
    const { person } = this.state;
    const {
      phoneCallModel,
      muteCall,
      transferCall,
      endCall,
      toggleDialPad,
      holdCall,
      loggedInUserActiveConferenceConversation,
      config,
    } = this.props;
    const loggedInUserInActiveConference = !isNullOrUndefined(
      loggedInUserActiveConferenceConversation
    );

    const { person: personStore } = this.context as RootStoreProps;

    const isVideoFeatureEnabled =
      personStore.personAvaliableFeatures.video.enabled;

    const disableVid =
      loggedInUserInActiveConference ||
      (!get(person, 'SupportsChat', false) &&
        !get(person, 'phoneNumber', false)) ||
      !isVideoFeatureEnabled;
    const moreButton = (
      <div className="btn-wrapper">
        <Popup
          inverted
          trigger={
            <Button
              id="more"
              disabled={
                phoneCallModel.phoneStore.ActivePhoneCall.isMerge ||
                phoneCallModel.phoneStore.phoneCalls.length <= 1
              }
              circular
              onClick={(e) => {
                e.currentTarget.blur();
                this.handleMerge(e);
              }}
            >
              <Button.Content visible>
                <Image src={moreIcon} className="show-onHover" />
                <Image src={moreIcon} className="hide-inital" />
              </Button.Content>
            </Button>
          }
          content="Merge call"
          position={'top center'}
        />
        <span
          className={cx(
            'btn-text',
            phoneCallModel.phoneStore.ActivePhoneCall.isMerge ||
              phoneCallModel.phoneStore.phoneCalls.length <= 1
              ? 'disabled'
              : 'enabled'
          )}
        >
          Merge
        </span>
      </div>
    );
    return [
      !phoneCallModel.isWarmTransfer && (
        <Grid.Column className="call-buttons nogap" textAlign="center">
          <div className="btn-wrapper">
            <Button
              id="mute"
              disabled={phoneCallModel.isMediaConnecting}
              active={phoneCallModel.isCallMuted}
              onClick={(e) => {
                e.currentTarget.blur();
                muteCall();
              }}
              circular
            >
              <Button.Content visible>
                <Image src={callMuteIcon} className="show-onHover" />
                <Image src={callMuteIconWhite} className="hide-inital" />
              </Button.Content>
            </Button>
            <span
              className={cx(
                'btn-text',
                phoneCallModel.isMediaConnecting ? 'disabled' : 'enabled'
              )}
            >
              Mute
            </span>
          </div>
          <div id="open-dialpad" className="btn-wrapper">
            <Button
              onClick={(e) => {
                e.currentTarget.blur();
                toggleDialPad();
              }}
              className={phoneCallModel.showDialPad ? 'bg-color' : ''}
              circular
            >
              <Button.Content visible>
                <Image src={callKeypadIcon} className="show-onHover" />
                <Image src={callKeypadIconWhite} className="hide-inital" />
              </Button.Content>
            </Button>
            <span className={cx('btn-text')}>
              {phoneCallModel.showDialPad ? 'Hide' : 'Show'}
            </span>
          </div>
          <div className="btn-wrapper">
            <Button
              id="hold-call"
              disabled={phoneCallModel.isMediaConnecting}
              active={phoneCallModel.isCallOnHold}
              onClick={(e) => {
                e.currentTarget.blur();
                holdCall();
              }}
              circular
            >
              <Button.Content visible>
                <Image src={holdCallIcon} className="show-onHover" />
                <Image src={holdCallIconWhite} className="hide-inital" />
              </Button.Content>
            </Button>
            <span
              className={cx(
                'btn-text',
                phoneCallModel.phoneStore.ActivePhoneCall.isMerge ||
                  phoneCallModel.isMediaConnecting
                  ? 'disabled'
                  : 'enabled'
              )}
            >
              Hold
            </span>
          </div>

          {isVideoFeatureEnabled && (
            <div className="btn-wrapper">
              <Popup
                inverted
                trigger={
                  <div
                    className={`flex-column ${
                      loggedInUserInActiveConference ? 'cursor-not-allowed' : ''
                    }`}
                  >
                    <Button
                      id="start-video-call"
                      disabled={disableVid}
                      active={false}
                      onClick={(e) => {
                        e.currentTarget.blur();
                        this.initiateVideoCall();
                      }}
                      circular
                    >
                      <Button.Content visible>
                        <Image
                          src={
                            loggedInUserInActiveConference
                              ? disabledVideoIcon
                              : videoIcon
                          }
                          className={
                            loggedInUserInActiveConference ||
                            phoneCallModel.phoneStore.ActivePhoneCall.isMerge
                              ? 'disabled-video-icon'
                              : 'show-onHover'
                          }
                        />
                        <Image
                          src={
                            loggedInUserInActiveConference
                              ? disabledVideoIcon
                              : videoIconWhite
                          }
                          className={
                            loggedInUserInActiveConference ||
                            phoneCallModel.phoneStore.ActivePhoneCall.isMerge
                              ? 'disabled-video-icon'
                              : 'hide-inital'
                          }
                        />
                      </Button.Content>
                    </Button>
                    <span
                      className={cx(
                        'btn-text',
                        loggedInUserInActiveConference ||
                          phoneCallModel.phoneStore.ActivePhoneCall.isMerge
                          ? 'disabled'
                          : 'enabled'
                      )}
                    >
                      Video
                    </span>
                  </div>
                }
              >
                <div className="ca-video-btn-tooltip">
                  {disableVid && (
                    <>
                      <div>Video Conference disabled</div>
                    </>
                  )}
                  {!loggedInUserInActiveConference &&
                    !phoneCallModel.phoneStore.ActivePhoneCall.isMerge &&
                    !disableVid && (
                      <>
                        <div>Start Video Conference</div>
                        <div>(This will end your call)</div>
                      </>
                    )}
                  {loggedInUserInActiveConference &&
                    !phoneCallModel.phoneStore.ActivePhoneCall.isMerge && (
                      <>
                        <div>Video Conference is Unavailable</div>
                        <div>(It is currently in use)</div>
                      </>
                    )}
                </div>
              </Popup>
            </div>
          )}

          <div className="btn-wrapper">
            <Button
              id="transfer-call"
              disabled={phoneCallModel.phoneStore.ActivePhoneCall.isMerge}
              active={false}
              onClick={(e) => {
                e.currentTarget.blur();
                transferCall();
              }}
              circular
            >
              <Button.Content visible>
                <Image src={transferIcon} className="show-onHover" />
                <Image src={transferIconWhite} className="hide-inital" />
              </Button.Content>
            </Button>
            <span
              className={cx(
                'btn-text',
                phoneCallModel.phoneStore.ActivePhoneCall.isMerge
                  ? 'disabled'
                  : 'enabled'
              )}
            >
              Transfer
            </span>
          </div>
          {moreButton}
          <div className="btn-wrapper">
            <Button
              id="end-call"
              className="end-call"
              disabled={phoneCallModel.isMediaConnecting}
              onClick={endCall}
              circular
            >
              <Button.Content visible>
                <Image src={endCallIcon} />
              </Button.Content>
            </Button>
            <span
              className={cx(
                'btn-text',
                phoneCallModel.isMediaConnecting ? 'disabled' : 'enabled'
              )}
            >
              Leave Call
            </span>
          </div>
        </Grid.Column>
      ),
      phoneCallModel.isWarmTransfer && (
        <div className="call-buttons active-warm-call-component nogap">
          {phoneCallModel.isCallConnected && (
            <div className="btn-wrapper" id="awc-transfer-btn-wrap">
              <Button
                id="warm-transfer"
                disabled={phoneCallModel.isMediaConnecting}
                active={false}
                onClick={this.warmTransfer}
                circular
              >
                <Button.Content visible>
                  <Image src={transferIcon} />
                </Button.Content>
              </Button>
              <span
                className={cx(
                  'btn-text',
                  phoneCallModel.isMediaConnecting ? 'disabled' : 'enabled'
                )}
              >
                Transfer
              </span>
            </div>
          )}
          <div className="btn-wrapper" id="awc-cancel-btn-wrap">
            <Button
              id="end-warm-call"
              disabled={phoneCallModel.isMediaConnecting}
              onClick={this.endWarmTransferCall}
              circular
            >
              <Button.Content visible>
                <Image src={endCallIcon} />
              </Button.Content>
            </Button>
            <span>Leave Call</span>
          </div>
        </div>
      ),
    ];
  }
}

export default inject(STORE_CONFIG)(observer(CallActiveButtons));
