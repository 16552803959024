import type { RootStore } from './RootStore';
import type { CallLogType } from 'Components/CallLogs/types';
import type { CallLogCategoryType } from 'Components/CallLogs/types';
import { mapImageToCallLogs } from 'Components/CallLogs/utils/mapImageToCallLogs';
import { action, observable, makeObservable } from 'mobx';
import { BaseStore } from './BaseStore';

export class CallLogsStore extends BaseStore {
  constructor(rootStore: RootStore) {
    super(rootStore);
    makeObservable(this);
  }

  @action clearAllData = () => {};

  // Cached first page call logs
  @observable firstPageCallLogs: {
    [props in CallLogCategoryType]: CallLogType[];
  } = { missed: [], incoming: [], outgoing: [], all: [] };

  @action setFirstPageCallLogs = (firstPageCallLogs: {
    [props in CallLogCategoryType]: CallLogType[];
  }) => (this.firstPageCallLogs = firstPageCallLogs);

  // Needed for Offline mode
  @observable lastSyncDate = '';
  @action setLastSyncDate = (lastSyncDate: string) =>
    (this.lastSyncDate = lastSyncDate);

  // Updates call logs based on pusher event
  @observable callLogPusherEvent: CallLogType = null;
  @action eventCreatedSuccesfully(callLog: CallLogType) {
    const callLogWithImage = mapImageToCallLogs([callLog])[0];
    this.callLogPusherEvent = callLogWithImage;

    const categoryToUpdate = callLogWithImage.category;

    // Inserts callLog in the "all" category, based on it's starting time
    const allInsertIndex = this.firstPageCallLogs['all'].findIndex(
      ({ startedAt }) => startedAt > callLogWithImage.startedAt
    );

    const sortedAllCallLogs =
      allInsertIndex > -1
        ? [
            ...this.firstPageCallLogs['all'].slice(0, allInsertIndex),
            callLogWithImage,
            ...this.firstPageCallLogs['all'].slice(allInsertIndex),
          ]
        : [callLogWithImage, ...this.firstPageCallLogs['all']];

    const updatedAll =
      this.firstPageCallLogs['all']?.length === 20
        ? sortedAllCallLogs.slice(0, -1)
        : sortedAllCallLogs;

    // Inserts callLog in the category, based on it's starting time
    const categoryInsertIndex = this.firstPageCallLogs[
      categoryToUpdate
    ].findIndex(({ startedAt }) => startedAt > callLogWithImage.startedAt);

    const sortedCategoryCallLogs =
      categoryInsertIndex > -1
        ? [
            ...this.firstPageCallLogs[categoryToUpdate].slice(
              0,
              categoryInsertIndex
            ),
            callLogWithImage,
            ...this.firstPageCallLogs[categoryToUpdate].slice(
              categoryInsertIndex
            ),
          ]
        : [callLogWithImage, ...this.firstPageCallLogs[categoryToUpdate]];

    const updatedCategory =
      this.firstPageCallLogs[categoryToUpdate]?.length === 20
        ? sortedCategoryCallLogs.slice(0, -1)
        : sortedCategoryCallLogs;

    this.setFirstPageCallLogs({
      ...this.firstPageCallLogs,
      ...(this.firstPageCallLogs['all']?.length && { all: updatedAll }),
      ...(this.firstPageCallLogs[categoryToUpdate]?.length && {
        [categoryToUpdate]: updatedCategory,
      }),
    });
  }
}
export default CallLogsStore;
