import {
  activePresenceIcon,
  doNotDisturbIcon,
  idlePresenceIcon,
  invisibleIcon,
} from './assets';

export enum Client {
  Unknown = 0,
  Communicator = 1,
}

export enum PHONE_TYPE {
  Home = 0,
  Work = 1,
  Cell = 2,
  Other = 3,
}

/** Keys used to track search results for each type of directory */
export const feedbackType = [
  {
    value: 'bug',
    display: 'Bug',
  },
  {
    value: 'feature',
    display: 'Feature Request',
  },
  {
    value: 'other',
    display: 'Other',
  },
];
export const PhoneNumberTypes = [
  { key: 'business', text: 'Business', value: 'business' },
  { key: 'home', text: 'Home', value: 'home' },
  { key: 'mobile', text: 'Mobile', value: 'mobile' },
  { key: 'pager', text: 'Pager', value: 'pager' },
  { key: 'business_fax', text: 'Business_fax', value: 'business_fax' },
  { key: 'home_fax', text: 'Home_fax', value: 'home_fax' },
  {
    key: 'organization_main',
    text: 'Organization_main',
    value: 'organization_main',
  },
  { key: 'assistant', text: 'Assistant', value: 'assistant' },
  { key: 'radio', text: 'Radio', value: 'radio' },
  { key: 'other', text: 'Other', value: 'other' },
];

export const EmailTypes = [
  { key: 'work', text: 'Work', value: 'work' },
  { key: 'personal', text: 'Personal', value: 'personal' },
];
export const imageFileTypes = [
  'image/jpeg',
  'image/png',
  'image/jpg',
  'image/svg+xml',
];
export const videoFileTypes = ['video/mp4', 'video/mov'];
export const documentsFileTypes = ['application/pdf'];
export type DIRECTORY_SEARCH_ID_TYPES =
  | 'TRANSFER'
  | 'USERS'
  | 'CONVERSATIONS'
  | 'DIRECTORY';

export type SEARCHABLE_TYPE =
  | 'SearchableDetailsConversation'
  | 'SearchableDetailsContact'
  | 'SearchableDetailsPerson';

export type CONTACT_TYPE =
  | 'Unknown'
  | 'Base'
  | 'Merged'
  | 'Shared'
  | 'Personal'
  | 'Person';

export type COUNT_AHEAD = 'Unknown' | 'Empty' | 'PartialPage' | 'FullPage';

export type CONTACT_VALUE_TYPE =
  | 'Mobile'
  | 'Work'
  | 'Home'
  | 'Other'
  | 'Unknown';

export type STATE_PRESENCE =
  | 'Active'
  | 'Away'
  | 'Idle'
  | 'OnCall'
  | 'OffLine'
  | 'DoNotDisturb'
  | 'Invisible';

export const possibleStatuses = [
  {
    text: 'Active',
    value: 'Active',
    image: { avatar: true, src: activePresenceIcon },
  },
  {
    text: 'Away',
    value: 'Away',
    image: { avatar: true, src: idlePresenceIcon },
  },
  {
    text: 'Do Not Disturb',
    value: 'DoNotDisturb',
    image: { avatar: true, src: doNotDisturbIcon },
  },
  {
    text: 'Invisible',
    value: 'Invisible',
    image: { avatar: true, src: invisibleIcon },
  },
];

export type ACCOUNT_PACKAGE_TYPE =
  | 'Unknown'
  | 'Xbp_Legacy'
  | 'Basic'
  | 'Metered'
  | 'Pro'
  | 'Virtual';

/**
 * Determines which "Mode" the `ContactList` is in, which will affect available actions for each item.
 *
 * @export
 * @enum {number}
 */
export enum CONTACT_LIST_MODE {
  /**
   * Displays the list of `Person`s, with contextual actions (call, message, etc.) for each item in the list.
   */
  Default = 0,
  /**
   * Displays the list of `Person`s who are **not** in the current `Channel`, with an action button to add them to the `Channel`.
   * This mode is used in the `ChannelMembers` component.
   */
  ChannelMembers = 1,
  /** Each `Person` will link to a chat/SMS or phone call `Conversation` (no additional action buttons) for display in `DetailsPanel` */
  NewConversation = 2,
}

/** Direction of a Call within a `MessageModel` */
export type CALL_DIRECTION = 'Unknown' | 'Incoming' | 'Outgoing';

/** Disposition of Call within a 'MessageModel'*/
export type CALL_DISPOSITION = 'Unknown' | 'Answered' | 'Missed' | 'Busy';

/** Possible `grouping` values for `ConversationModel` */
export type CONVERSATION_GROUPING =
  | 'Unknown'
  | 'OneOnOne'
  | 'Channel'
  | 'Group';

/** Using this for different state of a call */
export enum CALL_STATE {
  Available = 'AVAILABLE',
  Connecting = 'CONNECTING',
  Connected = 'CONNECTED',
}

/** (Client-side only) Flag indicating which direction loading is occurring. */
export enum LOAD_DIRECTION {
  /** Loading the initial data set */
  Initial = 0,
  /** Loading older data (ex. `MessageModel`s with older `created`) */
  Older = 1,
  /** Loading newer data (ex. `MessageModel`s with newer `created`) */
  Newer = 2,
}

/** Allowable prefixes for Mentions */
export type MENTION_PREFIX = '@' | '@pr';

export type RNS_LOG_LEVEL = 'success' | 'error' | 'warning' | 'info';

/** Position of a `react-notification-system` notification. Available: tr (top right), tl (top left), tc (top center), br (bottom right), bl (bottom left), bc (bottom center). */
export type RNS_NOTIFICATION_POSITION = 'tr' | 'tl' | 'tc' | 'br' | 'bl' | 'bc';
