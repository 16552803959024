import type { ContextContentItemsListProps } from './interfaces';
import { BulkheadRejectedError, Policy } from 'cockatiel';
import { LOAD_DIRECTION } from 'Constants/enums';
import { AxiosResponseT } from 'Interfaces/axiosResponse';
import { IManageLastScrollAndBackfill } from 'Interfaces/components';
import { get } from 'lodash';
import { toJS } from 'mobx';
import { MobXProviderContext } from 'mobx-react';
import { IPromiseBasedObservable } from 'mobx-utils';
import { ContactModelBase } from 'Models/ContactModel';
import { LastScroll } from 'Models/ConversationLastScroll';
import { GroupedMessagesContainer } from 'Models/GroupedMessagesContainer';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import useStayScrolled from 'react-stay-scrolled';
import type { RootStoreProps } from 'Stores/RootStore.types';
import { PersonModel } from '../../models';
import { bugsnagClient } from '../../utils/logUtils';
import { useAttachments } from '../Chat/hooks/attachments/useAttachments';
import { ContextContentItemsGroup } from './ContextContentItemsGroup';
import PrintMessageHeader from './PrintMessageHeader';

/**
 * **NOT AN OBSERVER.** This is intentional, it is designed to be a dumb component to support React Hooks.
 * @param props
 */
export function ContextContentItemsList(props: ContextContentItemsListProps) {
  const {
    assignLastScroll,
    callWithPerson,
    curConversation,
    deleteMessage,
    editMessage,
    editMessageDraftHtml,
    editMessageDraftRaw,
    getCurrentMessages,
    getEmojiPickerState,
    getOrConstructConversationLastScroll,
    getPerson,
    isBackfillingToReadMessage,
    isEditingMessageId,
    ldClient,
    lastReadMessageId,
    loadLinkPreview,
    loadMoreConversationMessages,
    loggedInPersonId,
    loggedInUserActiveConferenceConversation,
    mentionListOpen,
    messages,
    messagesWithLinkPreviewHeightCount,
    messageMentionFilter,
    navigateToConferenceByConfId,
    newestMessageOwnedByUserId,
    resolveConversationLinkPath,
    selectedMentionParticipantId,
    selectFilteredOtherParticipantPersonsInCurrentConversation,
    selectHasRecentActivityWithin,
    selectCanLoadOlderMessages,
    selectLoadMoreMessagesSkipIds,
    selectMarkedAsReadInfo,
    selectEpochMsSinceMarkedAsRead,
    selectParticipantPersons,
    selectPersonMessageStatus,
    selectPersonPresenceStatus,
    selectUnreadCounts,
    setConversationAndTotalUnreadCount,
    setEditMessageDraftHtml,
    setEditMessageDraftRaw,
    setEmojiPickerState,
    setIsEditingMessageId,
    setIsLoadingOlderMessages,
    setMarkedAsReadInfo,
    setMentionListOpen,
    setMessageMentionFilter,
    setSelectedMentionParticipantId,
    sortedMessageGroups,
    totalLinkPreviewHeightSum,
    totalMessageCount,
    totalUnreadCount,
    updateMyLastReadMessage,
    loadPersonByIdGetIfMissingGet,
    listOfPinnedMessages,
    selectParticipantsByConversationId,
    conversationStore,
    getFileDownloadLink,
    handleDownloadWithLink,
    ui,
    auth0,
    getExtrContactByPhoneNumber,
  } = props;

  const { contact } = React.useContext<RootStoreProps>(MobXProviderContext);
  const { getAttachmentsCount } = useAttachments();

  const conversationId = useParams().conversationId;
  const listRef = React.useRef<HTMLDivElement>();
  const [initialized, setInitialized] = React.useState<boolean>(false);
  const [lastMessageCount, setLastMessageCount] =
    React.useState<number>(totalMessageCount);
  const [lastUnreadCount, setLastUnreadCount] =
    React.useState<number>(totalUnreadCount);
  const [lastLinkPreviewHeightSum, setLastLinkPreviewHeightSum] =
    React.useState<number>(totalLinkPreviewHeightSum);
  const [
    lastMessagesWithLinkPreviewHeightCount,
    setLastMessagesWithLinkPreviewHeightCount,
  ] = React.useState<number>(messagesWithLinkPreviewHeightCount);
  const initCLS = toJS(getOrConstructConversationLastScroll(conversationId));
  const [scrolledToBottomOnFirstLoad, setScrolledToBottomOnFirstLoad] =
    React.useState<boolean>(initCLS.scrollHeight !== 0);
  const [userIsScrolling, setUserIsScrolling] = React.useState<boolean>(false);
  const { scroll, scrollBottom, stayScrolled, isScrolled } = useStayScrolled(
    listRef,
    { initialScroll: initCLS.scrollTop || Infinity, inaccuracy: 250 }
  );

  const onScroll = React.useCallback(() => {
    onCCILScrollBulkhead(
      listRef,
      {
        assignLastScroll,
        conversationId,
        getCurrentMessages,
        getOrConstructConversationLastScroll,
        loadMoreConversationMessages,
        isBackfillingToReadMessage,
        selectLoadMoreMessagesSkipIds,
        selectCanLoadOlderMessages,
        setIsLoadingOlderMessages,
        lastUnreadCount,
        scrolledToBottomOnFirstLoad,
        userIsScrolling,
        setUserIsScrolling,
      },
      scroll
    );
  }, [lastUnreadCount, scrolledToBottomOnFirstLoad, userIsScrolling]);
  // Typically you will want to use stayScrolled or scrollBottom inside
  // useLayoutEffect, because it measures and changes DOM attributes (scrollTop) directly
  React.useLayoutEffect(() => {
    const conversationLastScroll = toJS(
      getOrConstructConversationLastScroll(conversationId)
    );
    // if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
    //     console.debug('unread/totalMessage layoutEffect ', JSON.stringify({ initialized, lastUnreadCount, lastMessageCount }), 'totalUnreadCount', totalUnreadCount, 'totalMessageCount', totalMessageCount, `|| conversationLastScroll ${JSON.stringify(conversationLastScroll)} || initialRefScroll ${JSON.stringify(initialRefScroll)}`);
    // }
    // Initialize scroll position on mount
    if (!initialized) {
      if (totalUnreadCount === 0 && conversationLastScroll.scrollHeight === 0) {
        requestAnimationFrame(() => {
          // console.debug('layout initialize scrollBottom');
          try {
            scrollBottom();
            assignLastScroll(conversationId, {
              clientHeight: listRef.current.clientHeight,
              scrollHeight: listRef.current.scrollHeight,
              scrollTop: listRef.current.scrollTop,
            });
            setInitialized(true);
          } catch (e) {
            // just ignore the exception, this happens when switching back and forth quickly between conversations
            // this is due to the fact that this runs on the next frame, and the component might have been unmounted
          }
        });
      }
      // Already had a `conversationLastScroll` entry
      else if (
        totalUnreadCount === 0 &&
        conversationLastScroll.scrollHeight > 0
      ) {
        requestAnimationFrame(() => {
          // console.debug('layout had lastscroll next scrollTop', conversationLastScroll.scrollTop);
          try {
            scroll(conversationLastScroll.scrollTop);
            assignLastScroll(conversationId, {
              clientHeight: listRef.current.clientHeight,
              scrollHeight: listRef.current.scrollHeight,
              scrollTop: listRef.current.scrollTop,
            });
            setInitialized(true);
          } catch (e) {
            // just ignore the exception, this happens when switching back and forth quickly between conversations
            // this is due to the fact that this runs on the next frame, and the component might have been unmounted
          }
        });
      } else {
        // console.debug('layout default initialize with unreads');
        setInitialized(true);
      }
    }
    // Handle changes to unread/message count, link preview height sum
    else {
      if (
        totalMessageCount > lastMessageCount ||
        totalUnreadCount < lastUnreadCount
      ) {
        //  && newestMsgId === newestOwnedMsgId
        requestAnimationFrame(() => {
          // console.debug('layout stayScrolled', 'totalMessageCount > lastMessageCount', totalMessageCount > lastMessageCount, '(totalUnreadCount < lastUnreadCount)', (totalUnreadCount < lastUnreadCount));
          try {
            stayScrolled();
            assignLastScroll(conversationId, {
              clientHeight: listRef.current.clientHeight,
              scrollHeight: listRef.current.scrollHeight,
              scrollTop: listRef.current.scrollTop,
            });
          } catch (e) {
            // just ignore the exception, this happens when switching back and forth quickly between conversations
            // this is due to the fact that this runs on the next frame, and the component might have been unmounted
          }
        });
      }
      // Scroll down by the difference between the previous and current linkPreviewHeightSum
      else if (
        totalLinkPreviewHeightSum > lastLinkPreviewHeightSum &&
        totalUnreadCount === 0
      ) {
        requestAnimationFrame(() => {
          const heightDelta =
            totalLinkPreviewHeightSum - lastLinkPreviewHeightSum;
          const heightCountDelta =
            messagesWithLinkPreviewHeightCount -
            lastMessagesWithLinkPreviewHeightCount;

          // console.debug('layout scroll down by', heightDelta, 'totalLinkPreviewHeightSum > lastLinkPreviewHeightSum', totalLinkPreviewHeightSum, lastLinkPreviewHeightSum);
          try {
            scroll(
              conversationLastScroll.scrollTop +
                heightDelta +
                20 * heightCountDelta
            ); // Add 20px for top/bottom margin of each LinkPreview
            assignLastScroll(conversationId, {
              clientHeight: listRef.current.clientHeight,
              scrollHeight: listRef.current.scrollHeight,
              scrollTop: listRef.current.scrollTop,
            });
            setLastLinkPreviewHeightSum(totalLinkPreviewHeightSum);
            setLastMessagesWithLinkPreviewHeightCount(
              messagesWithLinkPreviewHeightCount
            );
          } catch (e) {
            // just ignore the exception, this happens when switching back and forth quickly between conversations
            // this is due to the fact that this runs on the next frame, and the component might have been unmounted
          }
        });
      }
    }

    setLastMessageCount(totalMessageCount);
    setLastUnreadCount(totalUnreadCount);
    // otherwise, let the MessageGroup scroll to its unread divider ref
  }, [
    totalUnreadCount,
    totalMessageCount,
    totalLinkPreviewHeightSum,
    messagesWithLinkPreviewHeightCount,
  ]);

  const pixelsScrollBottom = React.useRef<number | undefined>();
  if (listRef.current) {
    pixelsScrollBottom.current =
      listRef.current.scrollHeight -
      listRef.current.scrollTop -
      listRef.current.clientHeight;
  }

  React.useEffect(() => {
    if (
      pixelsScrollBottom.current !== undefined &&
      listRef.current &&
      getAttachmentsCount > 0
    ) {
      listRef.current.scrollTo({
        top:
          listRef.current.scrollHeight -
          listRef.current.clientHeight -
          pixelsScrollBottom.current,
        // @ts-ignore
        behavior: 'instant',
      });
    }
  }, [getAttachmentsCount]);

  const ccigNodes: React.ReactNode[] = [];
  sortedMessageGroups.forEach((mg, groupIndex) => {
    ccigNodes.push(
      <ContextContentItemsGroup
        auth0={auth0}
        setFileDeletePopup={ui.setFileDeletePopup}
        handleDownloadWithLink={handleDownloadWithLink}
        getFileDownloadLink={getFileDownloadLink}
        listOfPinnedMessages={listOfPinnedMessages}
        group={mg}
        key={mg.groupKey}
        totalUnreadCount={totalUnreadCount}
        callWithPerson={callWithPerson}
        conversationId={conversationId}
        curConversation={curConversation}
        deleteMessage={deleteMessage}
        editMessage={editMessage}
        editMessageDraftHtml={editMessageDraftHtml}
        editMessageDraftRaw={editMessageDraftRaw}
        getEmojiPickerState={getEmojiPickerState}
        getPerson={getPerson}
        groupIndex={groupIndex}
        isEditingMessageId={isEditingMessageId}
        isScrolled={isScrolled}
        loadLinkPreview={loadLinkPreview}
        lastReadMessageId={lastReadMessageId}
        loggedInPersonId={loggedInPersonId}
        loggedInUserActiveConferenceConversation={
          loggedInUserActiveConferenceConversation
        }
        mentionListOpen={mentionListOpen}
        messages={messages}
        messageMentionFilter={messageMentionFilter}
        navigateToConferenceByConfId={navigateToConferenceByConfId}
        newestMessageOwnedByUserId={newestMessageOwnedByUserId}
        resolveConversationLinkPath={resolveConversationLinkPath}
        scroll={scroll}
        scrollBottom={scrollBottom}
        selectedMentionParticipantId={selectedMentionParticipantId}
        selectFilteredOtherParticipantPersonsInCurrentConversation={
          selectFilteredOtherParticipantPersonsInCurrentConversation
        }
        selectHasRecentActivityWithin={selectHasRecentActivityWithin}
        isBackfillingToReadMessage={isBackfillingToReadMessage}
        selectMarkedAsReadInfo={selectMarkedAsReadInfo}
        selectEpochMsSinceMarkedAsRead={selectEpochMsSinceMarkedAsRead}
        selectParticipantPersons={selectParticipantPersons}
        selectPersonPresenceStatus={selectPersonPresenceStatus}
        selectUnreadCounts={selectUnreadCounts}
        setConversationAndTotalUnreadCount={setConversationAndTotalUnreadCount}
        setEditMessageDraftHtml={setEditMessageDraftHtml}
        setEditMessageDraftRaw={setEditMessageDraftRaw}
        setEmojiPickerState={setEmojiPickerState}
        setIsEditingMessageId={setIsEditingMessageId}
        setMarkedAsReadInfo={setMarkedAsReadInfo}
        setMentionListOpen={setMentionListOpen}
        setMessageMentionFilter={setMessageMentionFilter}
        setSelectedMentionParticipantId={setSelectedMentionParticipantId}
        stayScrolled={stayScrolled}
        updateMyLastReadMessage={updateMyLastReadMessage}
        selectPersonMessageStatus={selectPersonMessageStatus}
        conversationStore={conversationStore}
        setCopiedConferenceId={ui.setCopiedConferenceId}
        copiedConferenceId={ui.copiedConferenceId}
        copyToClipboard={ui.copyToClipboard}
        ldClient={ldClient}
        getExtrContactByPhoneNumber={getExtrContactByPhoneNumber}
        messageIdToScroll={ui.messageIdToScroll}
        setMessageIdToScroll={ui.setMessageIdToScroll}
      />
    );
  });
  // ** REMEMBER: THIS SFC IS NOT AN OBSERVER **
  const allParticipants = selectParticipantsByConversationId(conversationId);

  return (
    <div
      data-private
      className="context-content-items-list paddingTop overflow-scroll-y flex-grow-shrink flex-basis-100pct"
      key="ccilp"
      ref={listRef}
      onScroll={onScroll}
    >
      {curConversation?.grouping !== 'Channel' &&
        curConversation?.grouping !== 'Group' &&
        allParticipants?.case({
          fulfilled: (resp) => {
            const personsPBO: IPromiseBasedObservable<
              AxiosResponseT<PersonModel | ContactModelBase>
            >[] = resp.data.results.map(({ personId, phone }) => {
              const phoneNumber = phone?.replace('+', '');
              return personId
                ? loadPersonByIdGetIfMissingGet(personId)
                : contact.loadContactByPhoneNumber(phoneNumber);
            });
            const personsData = personsPBO
              .map((personPbo) => {
                return personPbo.state === 'fulfilled'
                  ? personPbo.case({
                      fulfilled: (resp) => resp.data,
                    })
                  : null;
              })
              .filter((person) => person);
            const extrContactResp = resp.data.results.find(
              (resp) => !resp.personId && (resp as any)?.phone
            );
            const extrContact = getExtrContactByPhoneNumber(
              (extrContactResp as any)?.phone
            );
            return (
              <PrintMessageHeader
                hasMessages={true}
                persons={personsData}
                extrContact={extrContact}
                loggedInPerson={loggedInPersonId}
                ui={ui}
              />
            );
          },
        })}
      {ccigNodes}
    </div>
  );
}
// ** REMEMBER: THIS SFC IS NOT AN OBSERVER **
export default ContextContentItemsList;

// ** SCROLL HANDLERS **
const scrollBulkhead = Policy.bulkhead(1, 1);
const onCCILScrollBulkhead = (
  ccilRef: React.MutableRefObject<HTMLDivElement>,
  manageLastScrollAndBackfill: IManageLastScrollAndBackfill,
  scroll: (scrollTop: number) => void
) => {
  return scrollBulkhead
    .execute(() => onCCILScroll(ccilRef, manageLastScrollAndBackfill, scroll))
    .then(
      (c) => c,
      (err) => {
        if (err instanceof BulkheadRejectedError) {
          console.warn(
            `onCCILScrollBulkhead overloaded with BulkheadRejectedError, try again.`,
            manageLastScrollAndBackfill
          );
        } else {
          console.error(
            `onCCILScrollBulkhead error (not BulkheadRejectedError)`,
            err,
            manageLastScrollAndBackfill
          );
          bugsnagClient.notify(err, (event) => {
            event.context = 'ContextContentItemsList';
            event.addMetadata('custom', { function: 'onCCILScrollBulkhead' });
          });
        }
        return err;
      }
    );
};

const lMCMBulkhead = Policy.bulkhead(1, 1);
const loadMoreConversationMessagesBulkhead = (
  manageLastScrollAndBackfill: IManageLastScrollAndBackfill,
  conversationId: string,
  msgs: GroupedMessagesContainer
) => {
  return lMCMBulkhead
    .execute(() =>
      manageLastScrollAndBackfill.loadMoreConversationMessages(
        conversationId,
        LOAD_DIRECTION.Older,
        msgs.OldestMessageId
      )
    )
    .then(
      (c) => c,
      (err) => {
        if (err instanceof BulkheadRejectedError) {
          console.warn(
            `loadMoreConversationMessagesBulkhead overloaded with BulkheadRejectedError, try again.`,
            manageLastScrollAndBackfill
          );
        } else {
          console.error(
            `loadMoreConversationMessagesBulkhead error (not BulkheadRejectedError)`,
            err,
            manageLastScrollAndBackfill
          );
          bugsnagClient.notify(err, (event) => {
            event.context = 'ContextContentItemsList';
            event.addMetadata('custom', {
              function: 'loadMoreConversationMessagesBulkhead',
            });
          });
        }
        return err;
      }
    );
};
/**
 * Execution callback for scrolling `ContextContentItemsList`.
 * Returns:
 * - If a possible scroll event occurred _OR_ manageLastScrollAndBackfill.selectCanLoadOlderMessages returned true, a `Promise` resolved with: `boolean` indicating whether the `LastScroll`'s values changed.
 * - Otherwise, a `Promise` resolved with `null`
 * - On failure, a rejected `Promise` containing the `Error`
 */
const onCCILScroll = (
  ccilRef: React.MutableRefObject<HTMLDivElement>,
  manageLastScrollAndBackfill: IManageLastScrollAndBackfill,
  scroll: (scrollTop: number) => void
) => {
  const conversationId = manageLastScrollAndBackfill.conversationId;
  const convLastScroll = toJS(
    manageLastScrollAndBackfill.getOrConstructConversationLastScroll(
      conversationId
    )
  );
  const msgs = manageLastScrollAndBackfill.getCurrentMessages(conversationId);
  const initialRefScroll = {
    scrollTop: ccilRef.current.scrollTop,
    scrollHeight: ccilRef.current.scrollHeight,
    clientHeight: ccilRef.current.clientHeight,
  };

  const scrollHeightDiff =
    convLastScroll.scrollTop - initialRefScroll.scrollTop;
  const lmmSkipIds =
    manageLastScrollAndBackfill.selectLoadMoreMessagesSkipIds(conversationId);
  // Backfill if needed
  if (
    msgs.OldestMessageId !== null &&
    convLastScroll.clientHeight !== 0 &&
    convLastScroll.scrollHeight !== 0 &&
    ccilRef.current.scrollTop <= ccilRef.current.clientHeight / 4 &&
    (lmmSkipIds === null || !lmmSkipIds.includes(msgs.OldestMessageId)) &&
    (ccilRef.current.scrollTop < convLastScroll.scrollTop ||
      (ccilRef.current.scrollTop === 0 && convLastScroll.scrollTop === 0)) &&
    manageLastScrollAndBackfill.selectCanLoadOlderMessages(
      manageLastScrollAndBackfill.conversationId
    )
  ) {
    manageLastScrollAndBackfill.setIsLoadingOlderMessages(conversationId, true);
    // if (NODE_ENV_LOCAL_OR_DEVELOPMENT) {
    //     console.debug(`onCCILScroll loadMoreConversationMessages for ${conversationId} || OldestMessageId ${msgs.OldestMessageId} || convLastScroll ${JSON.stringify(convLastScroll)}`);
    // }
    const lmmReq = loadMoreConversationMessagesBulkhead(
      manageLastScrollAndBackfill,
      conversationId,
      msgs
    );
    if (lmmReq) {
      return lmmReq.then(
        (res) => {
          const scrollTopAdjustment =
            ccilRef.current?.scrollHeight - convLastScroll.scrollHeight;
          const adjustedLs: LastScroll = {
            ...convLastScroll,
            scrollTop: convLastScroll.scrollTop + scrollTopAdjustment,
          };
          return new Promise((resolve) => {
            requestAnimationFrame(() => {
              let returnScroll: number;
              if (adjustedLs.scrollTop !== 0) {
                returnScroll = adjustedLs.scrollTop;
              } else {
                returnScroll =
                  get(res, 'data.results.length', 0) > 0
                    ? ccilRef.current?.clientHeight / 3
                    : 0;
                adjustedLs.scrollTop = returnScroll;
              }
              try {
                scroll(returnScroll);
              } catch (e) {
                // just ignore the exception, this happens when switching back and forth quickly between conversations
                // this is due to the fact that this runs on the next frame, and the component might have been unmounted
              }
              manageLastScrollAndBackfill.setIsLoadingOlderMessages(
                conversationId,
                false
              );
              resolve(
                manageLastScrollAndBackfill.assignLastScroll(
                  conversationId,
                  adjustedLs
                )
              ); // whether the `LastScroll`'s values changed
            });
          });
        },
        (e) => {
          console.error(
            `onCCILScroll Error loading older Messages with error`,
            e
          );
          manageLastScrollAndBackfill.setIsLoadingOlderMessages(
            conversationId,
            false
          ); // reset
          return e;
        }
      );
    } else {
      return Promise.reject(
        new Error('loadMoreConversationMessages result was null or undefined')
      );
    }
  } else if (
    manageLastScrollAndBackfill.selectCanLoadOlderMessages(conversationId)
  ) {
    let scrollTop = initialRefScroll.scrollTop;
    if (scrollHeightDiff > 0)
      manageLastScrollAndBackfill.setUserIsScrolling(true);
    if (
      manageLastScrollAndBackfill.lastUnreadCount === 0 &&
      !manageLastScrollAndBackfill.scrolledToBottomOnFirstLoad &&
      scrollHeightDiff <= 0 &&
      !manageLastScrollAndBackfill.userIsScrolling
    ) {
      scrollTop = initialRefScroll.scrollHeight - initialRefScroll.clientHeight;
      scroll(scrollTop);
    }
    initialRefScroll.scrollTop = scrollTop;
    return Promise.resolve(
      manageLastScrollAndBackfill.assignLastScroll(
        conversationId,
        initialRefScroll
      )
    ); // whether the `LastScroll`'s values changed
  }
  return Promise.resolve(null); // Nothing happened
};
